.alertMessage {
  display: none;
  top: calc(144px * var(--device-pixel-ratio));
  left: 0;
  text-align: left;
  font: calc(15px * var(--device-pixel-ratio))/calc(28px * var(--device-pixel-ratio)) "SF Pro Text Medium";
  letter-spacing: 0;
  color: #FFFFFF;
  z-index: 2;
  position: fixed;
  right: 0;
  margin: auto;
  width: fit-content;
  min-width: calc(506px * var(--device-pixel-ratio));
  max-width: calc(506px * var(--device-pixel-ratio));
  padding: calc(14px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(16px * var(--device-pixel-ratio));
  &.show {
    display: flex;
  }
  & .msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #1D1B2DB3 0% 0% no-repeat padding-box;
    border-radius: calc(29px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(29px * var(--device-pixel-ratio)) ;
    width: calc(58px * var(--device-pixel-ratio));
    transition: width,border 0.7s ease-in-out;
    &.in {
      animation: animateWidth 0.7s;
      width: 100%;
      & .text{
        animation: fadeIn 1s ease-in ;
      }
    }
    &.out {
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation: animateWidthOut 0.3s;
      width: calc(58px * var(--device-pixel-ratio));
      border-radius: calc(29px * var(--device-pixel-ratio));
      & .text {
        display: none;
        opacity: 0;

      }
    }
    & .text {
      max-width: calc(100% - 58px * var(--device-pixel-ratio));
      text-align: center;
      width: 100%;
      height: calc(58px * var(--device-pixel-ratio));
      line-height: normal;
      vertical-align: center;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .icon {
      width: calc(58px * var(--device-pixel-ratio));
      height: calc(58px * var(--device-pixel-ratio));
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      & img {
        width: calc(32px * var(--device-pixel-ratio));
        height: calc(32px * var(--device-pixel-ratio));
      }
    }
    &.error {
      & .icon{
        background: #F5222D66 0% 0% no-repeat padding-box;
        border: calc(1px * var(--device-pixel-ratio)) solid #F5222D;
        border-left: 0;
      }
      border: calc(1px * var(--device-pixel-ratio)) solid #F5222D;
    }
    &.info {
      & .icon{
        background:  #00A1AF33 0 0 no-repeat padding-box;
        border: calc(1px * var(--device-pixel-ratio)) solid #00A1AF;
        border-left: 0;
      }
      border: calc(1px * var(--device-pixel-ratio)) solid #00A1AF;
    }
    &.warning {
      background:transparent linear-gradient(90deg, #F77148 0%, #F7714833 100%) 0% 0% no-repeat padding-box;
    }
    &.done {
      background: transparent linear-gradient(90deg, #00CE7E 0%, #00CE7E33 100%) 0% 0% no-repeat padding-box;
    }
  }
}

@keyframes animateWidth {
  0% {
    width: calc(58px * var(--device-pixel-ratio));
  }
  100% {
    width: 100%
  }
}

@keyframes animateWidthOut {
  0% {
    width: 100%
  }
  100% {
    width: calc(58px * var(--device-pixel-ratio));
  }
}


@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}


.smallPc {
  .alertMessage {
    top: calc(116px * var(--device-pixel-ratio));;
    font-size: calc(16px * var(--device-pixel-ratio));
    min-width: calc(404px * var(--device-pixel-ratio));
    max-width: calc(404px * var(--device-pixel-ratio));

    & .msg {
      width: calc(46px * var(--device-pixel-ratio));

      &.out {
        width: calc(46px * var(--device-pixel-ratio));
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - calc(46px * var(--device-pixel-ratio)));
        height: calc(46px * var(--device-pixel-ratio));
      }

      & .icon {
        width: calc(46px * var(--device-pixel-ratio));
        height: calc(46px * var(--device-pixel-ratio));

        & img {
          width: calc(26px * var(--device-pixel-ratio));
          height: calc(26px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.tab {
  .alertMessage {
    top: calc(180px * var(--device-pixel-ratio));
    font-size: calc(12px * var(--device-pixel-ratio));
    width: calc(404px * var(--device-pixel-ratio));

    & .msg {
      width: calc(46px * var(--device-pixel-ratio));

      &.out {
        width: calc(46px * var(--device-pixel-ratio));
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - 46px * var(--device-pixel-ratio));
        height: calc(46px * var(--device-pixel-ratio));
      }

      & .icon {
        width: calc(46px * var(--device-pixel-ratio));
        height: calc(46px * var(--device-pixel-ratio));

        & img {
          width: calc(26px * var(--device-pixel-ratio));
          height: calc(26px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .alertMessage {
    top: 12.29vh;
    font-size: 10px;
    min-width: 87.7vw;
    max-width: 87.7vw;
    z-index: 3;
    & .msg {
      width: 36px;

      &.out {
        width: 36px;
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - 36px);
        height: 36px;
      }

      & .icon {
        width: 36px;
        height: 36px;

        & img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}