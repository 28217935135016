.action-panel {
    height: calc(78px * var(--device-pixel-ratio));
    margin: auto;
    width: fit-content;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &.show {
        opacity: 1; 
        pointer-events: all;
    }
    &-body {
        padding: 14px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        gap: 20px;
        background: #1D1B2DE6 0% 0% no-repeat padding-box;
        border: 1px solid #87879480;
        border-radius: 38px;
        &.template-button-list {
            .template-button-item:not(:first-child) {
                margin-left: 0;
            }
        }
        .description {
            display: block;
            max-width: 126px;
            text-align: center;
            font: normal normal 15px/24px SF Pro Text Bold;
            letter-spacing: 0.6px;
            color: #FFFFFF;
            text-shadow: 1px 1px 2px #000000;
        }
    }
    svg {
        width: 48px;
        height: 48px;
        border: 1px solid #87879480;
        border-radius: 50%;
    }
}

.smallPc {
    .action-panel {
        &-body {
            padding: 14px;
            padding-left: 16px;
            padding-right: 16px;
            gap: 16px;
            .description {
                font: normal normal 12px/19px SF Pro Text Bold;
                letter-spacing: 0.48px;
                max-width: 99px;
            }
        }
        svg {
            width: 38px;
            height: 38px;
        }
    }
}

.tab {
    .action-panel {
        top: 248px;

        &-body {
            padding: 14px;
            padding-left: 20px;
            padding-right: 20px;
            gap: 20px;
            .description {
                font: normal normal 12px/19px SF Pro Text Bold;
                letter-spacing: 0.48px;
                max-width: 99px;
            }
        }
        svg {
            width: 38px;
            height: 38px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .action-panel {
        top: -9.595vh;
        height: 8.395vh;
        width: 100vw;
        
        &-body {
            padding: 12px;
            padding-left: 20px;
            padding-right: 20px;
            gap: 20px;
            border: none;
            border-radius: 0;
            justify-content: center;
            border: 1px solid #87879480;
            .description {
                display: none;
            }
        }
        svg {
            width: 4.797vh;
            height: 4.797vh;
        }
    }
}