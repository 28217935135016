.bet-area,
.user-actions-area {
    width: 100%;
    height: 100%;
    perspective: calc(929px * var(--device-pixel-ratio));
    display: flex;
    justify-content: center;
}

.bet-area {
    // pointer-events: none;
    &.enabled-bet-areas {
        pointer-events: all;
        .board-body {
            svg.middle-area {
                &.board-middle-area,
                &.board-middle-area-1280,
                &.board-middle-area-768,
                &.board-middle-area-mobile {
                    & .other,
                    & .player,
                    & .banker,
                    & .tie,
                    & .name,
                    & .coefficient {
                        transition: fill 0.3s ease-in-out;
                        cursor: pointer;
                        opacity: 1;
                    }
                    & .other {
                        fill: rgba(83, 61, 113, 0.5);
                        &:hover {
                            fill: rgba(83, 61, 113, 0.7);
                        }
                        &:active {
                            fill: rgba(83, 61, 113, 1);
                        }
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                    & .player {
                        fill: rgba(0, 114, 195, 0.5);
                        &:hover {
                            fill: rgba(0, 114, 195, 0.7);
                        }
                        &:active {
                            fill: rgba(0, 114, 195, 1);
                        }
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                    & .banker {
                        fill: rgba(193, 0, 40, 0.5);
                        &:hover {
                            fill: rgba(193, 0, 40, 0.7);
                        }
                        &:active {
                            fill: rgba(193, 0, 40, 1);
                        }
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                    & .tie {
                        fill: rgba(15, 183, 0, 0.5);
                        &:hover {
                            fill: rgba(15, 183, 0, 0.7);
                        }
                        &:active {
                            fill: rgba(15, 183, 0, 1);
                        }
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                    & .name {
                        fill: rgba(255, 255, 255, 1);
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                    & .coefficient {
                        fill: rgba(255, 204, 0, 1);
                        &.disabled {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
    }
    .book-show-top {
        position: absolute;
        width: 100%;
        top: 0;
        .perfect-pair-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            top: -20px;
            left: 5%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .either-pair-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            top: -20px;
            right: 5%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
    }
    .book-show-bottom {
        bottom: 0;
        .player-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 29%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .banker-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 0;
            right: 29%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .tie-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .player-pair-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 5px;
            left: 12%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
        .banker-pair-book {
            background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
            border-radius: 12px;
            min-width: calc(64px*var(--device-pixel-ratio));
            padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
            text-align: center;
            color: #fff;
            position: absolute;
            bottom: 5px;
            right: 12%;
            font-size: calc(12px * var(--device-pixel-ratio));
            &.green {
                color: rgba(50, 215, 75, 1);
            }
            &.red {
                color: rgba(255, 69, 58, 1);
            }
        }
    }
}

.middlePc {
    .bet-area {
        .book-show-top {
            .perfect-pair-book {
                top: -15px;
                left: 15%;
            }
            .either-pair-book {
                top: -15px;
                right: 15%;
            }
        }
        .book-show-bottom {
            .player-book {
                bottom: -5px;
                left: 33.5%;
            }
            .banker-book {
                bottom: -5px;
                right: 33.5%;
            }
            .player-pair-book {
                bottom: -5px;
                left: 19.5%;
            }
            .banker-pair-book {
                bottom: -5px;
                right: 19.5%;
            }
        }
    }
}

.smallPc {
    .bet-area {
        .book-show-top {
            .perfect-pair-book {
                top: -10px;
                left: 5%;
            }
            .either-pair-book {
                top: -10px;
                right: 5%;
            }
        }
        .book-show-bottom {
            .player-book {
                bottom: -5px;
                left: 28.5%;
            }
            .banker-book {
                bottom: -5px;
                right: 28.5%;
            }
            .player-pair-book {
                bottom: -5px;
                left: 10.5%;
            }
            .banker-pair-book {
                bottom: -5px;
                right: 10.5%;
            }
        }
    }
}

.tab {
    .bet-area {
        &.threeD {
            & .bet-body {
                & .middle-area {
                    height: calc(164px * var(--device-pixel-ratio));
                }
            }
        }
        .book-show-top {
            .perfect-pair-book {
                top: -15px;
                left: 8%;
            }
            .either-pair-book {
                top: -15px;
                right: 8%;
            }
        }
        .book-show-bottom {
            .player-book {
                bottom: -10px;
            }
            .banker-book {
                bottom: -10px;
            }
            .tie-book {
                bottom: -5px;
            }
            .player-pair-book {
                bottom: -10px;
                left: 13%;
            }
            .banker-pair-book {
                bottom: -10px;
                right: 13%;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .bet-area {
        .book-show-top {
            .perfect-pair-book {
                top: -12px;
                left: 8%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
            .either-pair-book {
                top: -12px;
                right: 8%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
        }
        .book-show-bottom {
            .player-book {
                bottom: -13px;
                left: 24.5%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
            .banker-book {
                bottom: -13px;
                right: 24.5%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
            .tie-book {
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
            .player-pair-book {
                bottom: 0;
                left: 8%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
            .banker-pair-book {
                bottom: -5px;
                right: 8%;
                min-width: calc(40px * var(--device-pixel-ratio));
                padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                font-size: calc(10px * var(--device-pixel-ratio));
            }
        }
    }
}