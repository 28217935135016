.board {
    &-body {
        display: flex;
        align-items: center;
        transform: rotate3d(-10, 0, 0, 0deg);
        transition: transform .5s, margin .5s;
        transform-origin: center;
    }
    &-svg {
        &-item {
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
            &:hover {
                &.player {
                    & path {
                        stroke: #7BB1A7;
                        fill: #0ABF9E;
                    }
                }
                &.banker {
                    & path {
                        stroke: #B18093;
                        fill: #DB3F7D;
                    }
                }
                &.tie {
                    & g {
                        stroke: #eaeaec;
                        fill: #eaeaec;
                    }
                    .text-tie {
                        fill: #878794;
                    }
                }
                &.perfect {
                    & path {
                        stroke: none;
                        fill: #7B838C;
                    }
                }
            }
            &.active,
            &:active {
                &.player {
                    & path {
                        stroke: #7BB1A7;
                        fill: #0ABF9E;
                    }
                }
                &.banker {
                    & path {
                        stroke: #B18093;
                        fill: #DB3F7D;
                    }
                }
                &.tie {
                    & g {
                        stroke: #eaeaec;
                        fill: #eaeaec;
                    }
                    .text-tie {
                        fill: #878794;
                    }
                }
                &.perfect {
                    & path {
                        stroke: none;
                        fill: #7B838C;
                    }
                }
            }
        }
    }
}

.threeD:not(.user-actions-area) {
    .board {
        &-body {
            transform: rotate3d(-10, 0, 0, -45deg) translate(0, 12px) scaleX(0.98) scaleY(1.03);
        }
    }
}

.smallPc {
    .bet-area {
        .board-body {
            align-items: flex-start;
            gap: 16px;
            &-center {
                .middle-area {
                    width: 474px;
                    height: 114px;
                }
            }
        }
        &.threeD {
            width: calc(678px * var(--device-pixel-ratio));
        }
    }
    .threeD:not(.user-actions-area) {
        .board-body {
            transform: rotate3d(-10, 0, 0, -45deg) translate(0px, -3px) scaleX(1.01) scaleY(1.1);
        }
    }
}

.tab {
    .board {
        &-body {
            align-items: flex-end;
            gap: 0px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .bet-area {
        .board {
            &-body {
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 5.847vh;
                &-center {
                    width: 78.93vw;
                    height: 20.26vw;
                }
            }
        }
        &.threeD {
            .board-body {
                transform: unset;
            }
        }
    }
}