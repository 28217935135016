.history-filter {
  margin: 0 calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
  position: relative;
  border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
  &-form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-item {
      margin-bottom: calc(16px * var(--device-pixel-ratio));
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      & .label {
        font: calc(15px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing: calc(0.6px * var(--device-pixel-ratio));
        color: $form-label-color;
        margin-top: calc(10px * var(--device-pixel-ratio));
        margin-right:  calc(8px * var(--device-pixel-ratio));
      }
      &.dataPicker {
        & .area {
          padding: calc(12px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio));
          background: rgba(29, 27, 45, 0.8) 0 0 no-repeat padding-box;
          border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
          border-radius: calc(20px * var(--device-pixel-ratio));
          margin: 0 calc(1px * var(--device-pixel-ratio));
          width: calc(289px * var(--device-pixel-ratio));
          height: calc(40px * var(--device-pixel-ratio));
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon {
            width: calc(28px * var(--device-pixel-ratio));
            height: calc(28px * var(--device-pixel-ratio));
            & svg {
              width: 100%;
              height: 100%;
            }
          }
          .react-datepicker-wrapper {
            width: calc(113px * var(--device-pixel-ratio));
            & .react-datepicker__input-container {
              width: 100%;
              & input {
                font: 300 calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Light";
                letter-spacing: calc(0.56px * var(--device-pixel-ratio));
                color: $datePicker-input-color;
                border: transparent;
                background: transparent;
                width: 100%;
                &:active, &:focus {
                  outline: 0;
                  box-shadow: none;
                }
              }
            }
          }

          .react-datepicker-popper {
            &[data-placement^=bottom] {
              padding-top: calc(20px * var(--device-pixel-ratio));
            }
            & .react-datepicker {
              width: calc(270px * var(--device-pixel-ratio));
              border-radius: calc(12px * var(--device-pixel-ratio));
              background-color: transparent;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(2px);
                z-index: -1;
                border-radius: calc(12px * var(--device-pixel-ratio));
                background-color: $datePicker-bg-color;
              }

              & .react-datepicker__triangle {
                &:before, &:after {
                  content: unset;
                }
              }

              & .react-datepicker__month-container {
                background-color: $datePicker-bg-color;
                width: 100%;

                & .react-datepicker__header {
                  border-color: $datePicker-header-bottom-border-color;
                  background-color: transparent;

                  & .react-datepicker__day-names {
                    & .react-datepicker__day-name {
                      font: 500 calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      color: $datePicker-header-weekDay-color
                    }
                  }

                  & .react-datepicker__current-month {

                  }

                  & .datePicker-header {
                    position: relative;
                    padding: calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: space-between;
                    width: 100%;
                    font: calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    color: $datePicker-header-color;
                    text-shadow: 0px 0px 1px $datePicker-header-shadow-color;

                    & .monthsArrow, & .yearsArrow {
                      width: calc(20px * var(--device-pixel-ratio));
                      height: calc(20px * var(--device-pixel-ratio));
                      border-radius: calc(2px * var(--device-pixel-ratio));
                      background-color: transparent;
                      border-color: transparent;
                      cursor: pointer;
                      &:hover {
                        background-color: rgba(84, 104, 126, 0.4);
                      }
                      &:active {
                        background-color: rgba(84, 104, 126, 0.9);
                      }

                      &.monthsArrow-right, &.yearsArrow-right {
                        transform: rotate(180deg);

                      }
                    }
                  }
                }

                & .react-datepicker__month {
                  & .react-datepicker__week {
                    display: flex;

                    & .react-datepicker__day {
                      width: 100%;
                      height: calc(24px * var(--device-pixel-ratio));
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font: 500 calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      color: $datePicker-header-mount-weekDay-color;

                      &.react-datepicker__day--outside-month {
                        color: rgb($datePicker-header-mount-weekDay-color, 0.5);
                      }
                    }

                    & .react-datepicker__day--selected,
                    & .react-datepicker__day--in-selecting-range,
                    & .react-datepicker__day--in-range,
                    & .react-datepicker__month-text--selected,
                    & .react-datepicker__month-text--in-selecting-range,
                    & .react-datepicker__month-text--in-range,
                    & .react-datepicker__quarter-text--selected,
                    & .react-datepicker__quarter-text--in-selecting-range,
                    & .react-datepicker__quarter-text--in-range,
                    & .react-datepicker__year-text--selected,
                    & .react-datepicker__year-text--in-selecting-range,
                    & .react-datepicker__year-text--in-range,
                    & .react-datepicker__day:hover,
                    & .react-datepicker__month-text:hover,
                    & .react-datepicker__quarter-text:hover,
                    & .react-datepicker__year-text:hover {
                      border-radius: calc(2px * var(--device-pixel-ratio));
                      background-color: $datePicker-header-mount-weekDay-selected-color;
                    }

                    & .react-datepicker__day:active,
                    & .react-datepicker__month-text:active,
                    & .react-datepicker__quarter-text:active,
                    & .react-datepicker__year-text:active {
                      background-color: rgba(84, 104, 126, 0.9);
                      border-radius: calc(2px * var(--device-pixel-ratio));
                    }
                  }
                }
              }

              & .react-datepicker__today-button {
                background: transparent;
                border-color: $datePicker-header-bottom-border-color;
                text-shadow: 0px 0px calc(1px * var(--device-pixel-ratio)) $datePicker-footer-text-shadow-color;
                color: $datePicker-footer-text-color;
                padding: calc(15px * var(--device-pixel-ratio)) 0;
                &:hover {
                  background: rgba(84, 104, 126, 0.4);
                }

                &:active {
                  background: rgba(84, 104, 126, 0.9);
                }
              }
            }

          }
        }
      }
    }
  }
  &-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: calc(8px * var(--device-pixel-ratio));
    margin-bottom: calc(16px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .history-filter {
    height: 100px;
    position: relative;

    &-form {
      &-item {
        & .label {
          font-size: 2.93vw;
          margin-right: 1.06vw;
          margin-top: 8px;
        }

        &.dataPicker {
          & .area {
            padding: 8px 12px;
            width: auto;
            max-width: 154px;
            height: 28px;

            & .icon {
              width: 12px;
              height: 12px;
            }

            & .react-datepicker-wrapper {
              width: 51px;

              & .react-datepicker__input-container {
                & input {
                  font-size: 2.93vw;
                }
              }
            }
          }
        }
      }
    }
  }
}



