.action-type-info {
    height: 28px;
    opacity: 0;
    text-align: center;
    font: normal normal 15px/18px SF Pro Text Medium;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    text-shadow: 1px 1px 2px #000000;
    &.show {
        opacity: 1;
        transition: opacity 1s;
    }
}

.smallPc{
    .action-type-info {
        height: 22px;
        font: normal normal  12px/14px SF Pro Text Medium;
        letter-spacing: 0.48px;
    }
}

.tab{
    .action-type-info {
        position: relative;
        top: -237px;
        height: 22px;
        font: normal normal 12px/14px SF Pro Text Medium;
        letter-spacing: 0.48px;
    }
}

@media only screen and (max-width: 540px) {
    .action-type-info {
        order: 1;
        height: 19px;
        font: normal normal 10px/12px SF Pro Text Medium;
        letter-spacing: 0.4px;
    }
}