.chips-board {
  position: relative;
  height: calc(78px * var(--device-pixel-ratio));
  margin: auto;
  width: fit-content;
  opacity: 0;
  transition: opacity 1s;
  pointer-events: none;
  &.mobile {
    display: none;
  }
  &.desktop {
    display: block;
  }
  & .chips-board-list {
    padding: 0 calc(20px * var(--device-pixel-ratio));
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: auto;
    width: 100%;
    height: 100%;
    background: #1D1B2DB3 0% 0% no-repeat padding-box;
    border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
    border-radius: calc(42px * var(--device-pixel-ratio));
    & .template-button-list {
      width: fit-content;
      margin-right: calc(0px * var(--device-pixel-ratio));
      margin-left: calc(0px * var(--device-pixel-ratio));
    }
  }
  .singleChip {
    &:not(:last-child) {
      margin-right: calc(0px * var(--device-pixel-ratio));
    }
  }
}

.show-chip-board {
  opacity: 1;
  pointer-events: all;
  .singleChip {
    cursor: pointer;
  }
}

.smallPc {
  .chips-board {
    height: calc(66px * var(--device-pixel-ratio));
    margin: auto;
    &-list {
      padding: 0 calc(12px * var(--device-pixel-ratio));
      .template-button-list {
        margin-right: calc(0px * var(--device-pixel-ratio));
        margin-left: calc(0px * var(--device-pixel-ratio));
      }
      & .singleChip {
        width: calc(60px * var(--device-pixel-ratio));
        height: calc(60px * var(--device-pixel-ratio));
        &:not(:last-child) {
          margin-right: calc(0px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.tab {
  .chips-board {
    height: calc(66px * var(--device-pixel-ratio));
    margin-bottom: calc(32px * var(--device-pixel-ratio));
    order: 0;
    &-list {
      height: 100%;
      padding: 0 calc(14px * var(--device-pixel-ratio));
      .template-button-list {
        margin-right: calc(0px * var(--device-pixel-ratio));
        margin-left: calc(0px * var(--device-pixel-ratio));
        .template-button-item {
          width: calc(38px * var(--device-pixel-ratio));
          height: calc(38px * var(--device-pixel-ratio));
        }
      }
      & .singleChip {
        width: calc(60px * var(--device-pixel-ratio));
        height: calc(60px * var(--device-pixel-ratio));
        &:not(:last-child){
          margin-right: calc(0px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .chips-board {
    width: 100vw;
    position: absolute;
    margin: 0 auto;
    height: 8.9vh;
    z-index: 4;
    top: -12.2vh;
    left: 0;
    max-height: 14.6vw;
    &.mobile {
      order: 2;
    }
    & .chips-board-list {
      border-radius: 0;
      max-height: 89.6vw;
      padding: 3vw 0;
      border: 1px solid #87879480;
      border-left: 0;
      border-right: 0;
      position: relative;
      &:before, &:after {
        content: "";
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
        pointer-events: none;
      }
      &:before {
        left: 0;
        background: linear-gradient(to right, #181523, transparent);
      }
      &:after {
        right: 0;
        background: linear-gradient(to left, #181523, transparent);
      }
      & .singleChip {
        transition: unset;
        justify-content: center;
        align-items: center;
        display: flex;
        &:not(:last-child) {
          margin-right: 0;
        }
        &:hover {
          border: 0;
        }
        &:active, &.active {
          background-color: transparent;
          & .item {
            border-radius: 100%;
            background-color:   #EAEAEC;
          }

        }
        & .item {
          width: 100%;
          height: 100%;
          & svg {
            width: 100%;
            height: 100%;
            position: relative;
            transform-origin: unset;
          }

        }

      }
      & .swiper {
        &-slide.singleChip {
          width: 13.3vw;
          height: 13.3vw;
          max-width: 7.496vh;
          max-height: 7.496vh;
          &:not(:last-child) {
            margin-right: 1.6vw;
          }
        }
      }
    }
  }
}