.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@assets/img/loadingBg.svg"), radial-gradient(closest-side at 50% 50%, #1D1B2D 0%, #000000 100%);
  z-index: 21;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  & .border-box {
    // width: 100%;
    // height: 93.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: left top;
    & .border-left-right {
      &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: calc(128px * var(--device-pixel-ratio));
        height: calc(630px * var(--device-pixel-ratio));
        background-repeat: no-repeat;
        background-image: url("@assets/img/border/loadingborderLeftRight.svg");
        background-position: right 0.885vw center;
      }
      &:before {
        right: calc(185px * var(--device-pixel-ratio));
        transform: rotate(180deg);
      }
      &:after {
        left: calc(185px * var(--device-pixel-ratio));
      }
    }
    & .border-top-bottom {
      &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: calc(1322px * var(--device-pixel-ratio));
        height: calc(128px * var(--device-pixel-ratio));
        background-repeat: no-repeat;
        background-image: url("@assets/img/border/loadingborderTopBottom.svg");
        background-position: bottom 1.57% center;
      }
      &:before {
        top: calc(91px * var(--device-pixel-ratio));
      }
      &:after {
        transform: rotate(180deg);
        bottom: calc(91px * var(--device-pixel-ratio));
      }
    }
  }
  &-info-box {
    mix-blend-mode: hard-light;
    border: calc(1px * var(--device-pixel-ratio)) solid #EAEAEC1A;
    border-radius: calc(14px * var(--device-pixel-ratio));
    opacity: 1;
    height: calc(628px * var(--device-pixel-ratio));
    width: calc(1320px * var(--device-pixel-ratio));
    padding: calc(160px * var(--device-pixel-ratio)) calc(160px * var(--device-pixel-ratio));
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    & .title {
      text-align: center;
      font: 30px "SF Pro Text Bold";
      letter-spacing: calc(0.6px * var(--device-pixel-ratio));
      color: #fff;
      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
      width: fit-content;
      margin: 0 auto;
    }
    & .icon-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .icon {
        width: 100%;
        text-align: center;
        transition: opacity 0.3s ease-in-out;
        opacity: 0.5;
        & img {
          width: calc(34px * var(--device-pixel-ratio));
        }
        &.active {
          opacity: 1;
        }
      }
    }
    & .description {
      text-align: center;
      color: #fff;
      font: 15px "SF Pro Text Medium";
      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) $main-text-shadow-color;
    }
    & .loader {
     & svg{
       &  .dashed{
         stroke-dasharray: calc(100% / 3) 20;
         stroke-width: 6;

         &.load-part {
           -webkit-filter:  drop-shadow(0px 1px 2px rgba(255, 204, 0, 0.7)) drop-shadow(0px -1px 3px rgba(255, 204, 0, 0.7)) ;
           filter: drop-shadow(0px 1px 2px rgba(255, 204, 0, 0.7)) drop-shadow(0px -1px 3px rgba(255, 204, 0, 0.7));
           animation-fill-mode: forwards;
         }
         &.content {
           //filter: drop-shadow(0px -1px 0px #FFCC00)  ;
         }
       }
      }
    }
  }
}

.smallPc {
  .loading {
    & .border-box {
      & .border-left-right {
        &:before, &:after {

        }
        &:before {
          right: calc(-18px * var(--device-pixel-ratio));
        }
        &:after {
          left: calc(-18px * var(--device-pixel-ratio));
        }
      }
      & .border-top-bottom {
        &:before, &:after {

        }
        &:before {
          top: calc(-14px * var(--device-pixel-ratio));
        }
        &:after {
          bottom: calc(-14px * var(--device-pixel-ratio));
        }
      }
    }
    &-info-box {
      width: calc(1080px * var(--device-pixel-ratio));
      height: calc(564px * var(--device-pixel-ratio));
      padding: calc(140px * var(--device-pixel-ratio)) calc(140px * var(--device-pixel-ratio));
      & .title {
      }
      & .icon-box {
        & .icon {

          & img {
          }
          &.active {
          }
        }
      }
      & .description {

      }
    }
  }
}

.tab {
  .loading {
    & .border-box {
      & .border-left-right {
        &:before, &:after {
          width: calc(128px * var(--device-pixel-ratio));
          height: calc(458px * var(--device-pixel-ratio));
        }
        &:before {
          right: calc(-41px * var(--device-pixel-ratio));
        }
        &:after {
          left: calc(-41px * var(--device-pixel-ratio));
        }
      }
      & .border-top-bottom {
        &:before, &:after {
          width: calc(606px * var(--device-pixel-ratio));
          height: calc(128px * var(--device-pixel-ratio));
        }
        &:before {
          top: calc(155px * var(--device-pixel-ratio));
        }
        &:after {
          bottom: calc(155px * var(--device-pixel-ratio));
        }
      }
    }
    &-info-box {
      width: calc(604px * var(--device-pixel-ratio));
      height: calc(456px * var(--device-pixel-ratio));
      padding: calc(100px * var(--device-pixel-ratio)) calc(100px * var(--device-pixel-ratio));
      & .title {
        line-height: calc(53px * var(--device-pixel-ratio));
        margin-top: calc(-10px * var(--device-pixel-ratio));
      }
      & .icon-box {
        & .icon {

          & img {
          }
          &.active {
          }
        }
      }
      & .description {

      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .loading {
    align-items: center;
    justify-content: center;
    display: flex;
    & .border-box {
      & .border-left-right {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:before, &:after {
          left: unset;
          width: 8.5vw;
          background-size: cover;
          height: 56.37vh;
          margin: auto 0;
        }
        &:before {
          left: 1%;
        }
        &:after {
          right: 1%;
        }
      }
      & .border-top-bottom {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:before, &:after {
          width: 76.26vw;
          height: 10.79vh;
          background-size: cover;
          background-position: center;
        }
        &:before {
          top: 9%;

        }
        &:after {
          bottom: 9%;
        }
      }
    }
    &-info-box {
      width: 75.73vw;
      height: 56.07vh;
      padding: 11.994vh 8.79vw;
      border-radius: 41px;
      & .title {
        font: 5.13vw "SF Pro Text Bold";
        line-height: 5.44vh;
      }
      & .icon-box {
        & .icon {
          & img {
            width: 5.3vw;
            height: 2.99vh;
          }
          &.active {
          }
        }
      }
      & .description {
        font: 10px "SF Pro Text Medium";
      }
    }
  }
}