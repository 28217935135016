.user-won {
    position: absolute;
    width: 353px;
    height: 307px;
    left: 50%;
    z-index: 2;
    top: 286px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity .5s;
    &.show {
        opacity: 1;
    }

    .price {
        position: absolute;
        text-align: center;
        font: normal normal 50px/60px SF Pro Text Bold;
        letter-spacing: 2px;
        color: #FFCC00;
        text-shadow: 1px 1px 2px #000000;
        font-weight: 900;
        left: 50%;
        transform: translateX(-50%);
        top: 164px;
        z-index: 1;
    }
    .title {
        position: absolute;
        font: normal normal 23px/28px SF Pro Text Bold;
        letter-spacing: 0.92px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
        left: 50%;
        transform: translateX(-50%);
        top: 21%;
        z-index: 1;
    }
    svg {
        position: absolute;
        &.background {
            width: 353px;
            height: 307px;
        }
        &.corona {
            width: 55px;
            height: 55px;
            left: 50%;
            top: 47%;
            transform: translate(-50%, -50%);
        }
    }
}

.smallPc {
    .user-won {
        width: 285px;
        height: 252px;
        top: 185px;
    
        .price {
            font: normal normal 45px/54px SF Pro Text Bold;
            letter-spacing: 1.8px;
            font-weight: 900;
            top: 51%;
        }
        .title {
            font: normal normal 20px/24px SF Pro Text Bold;
            letter-spacing: 0.8px;
            top: 22%;
        }
        svg {
            &.background {
                width: 100%;
                height: 100%;
            }
            &.corona {
                width: 47px;
                top: 45%;
                height: 41px;
            }
        }
    }
    
}

.tab {
    .user-won {
        width: 226px;
        height: 226px;
        top: 207px;
    
        .price {
            font: normal normal 36px/43px SF Pro Text Bold;
            letter-spacing: 1.44px;
            top: 51.5%;
        }
        .title {
            font: normal normal 15px/18px SF Pro Text Bold;
            letter-spacing: 0.6px;
            top: 56px;
        }
        svg {
            &.background {
                width: 100%;
                height: 100%;
            }
            &.corona {
                width: 33px;
                height: 33px;
                top: 46%;
            }
        }
    }
}

@media only screen and (max-width: 540px)  {
    .user-won {
        width: 150px;
        height: 127px;
        top: 12%;
    
        .price {
            font: normal normal 22px/26px SF Pro Text Bold;
            letter-spacing: 0.88px;
            font-weight: 900;
            top: 50%;
        }
        .title {
            font: normal normal 10px/12px SF Pro Text Bold;
            letter-spacing: 0.4px;
        }
        svg {
            &.background {
                width: 150px;
                height: 127px;
            }
            &.corona {
                width: 25px;
                height: 21px;
                top: 43%;
            }
        }
    }
}