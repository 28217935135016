.bet-area {
    .board-body-item {
        svg {
            width: 122px;
            height: 82px;
        }
    }
}

.tab {
    .bet-area {
        .board-body-item {
            svg {
                width: 100px;
                height: 68px;
            }
        }
    }
}
@media only screen and (max-width: 540px) {
    .bet-area {
        .board-body-item {
            svg {
                width: 21.33vw;
                height: 12.8vw;
            }
        }
    }
}