@import "base/colors";
@import "./../fonts/svgFonts/fonts/iconfont";
@import "base/reset";
@import "base/typography";
@import "components/tooltip";
@import "components/dropDown";
@import "components/buttons";
@import "layouts/header";
@import "components/videoStream";
@import "components/templateButton";
@import "layouts/mainLayout";
@import "components/videoStream";
@import "components/winnerList";
@import "components/history";
@import "components/modal";
@import "components/betBoard";
@import "components/betArena";
@import "components/chipsList";
@import "components/singleChip";
@import "components/infoPopUp";
@import "components/rightMenu";
@import "components/cards";
@import "components/connectMessage";
@import "components/loading";
@import "components/alertMessage";
@import "components/gameHistory";
@import "components/roundInfo";
@import "components/elementLoading";
@import "components/historyFilter";
@import "components/oddElement";
@import "components/boardMiddleArea";
@import "components/board";
@import "components/timer";
@import "components/userWon";
@import "components/chipsListOnBoard";
@import "components/userActions";
@import "components/actionTypeInfo";
@import "components/hands";
@import "components/actionPanel";
@import "components/userWon";
@import "components/hideToolBar";
@import "components/roundHistory";
@import "components/userCenterInfo";
@import "base/responsive";
@import "components/activeBets";
@import "components/buttons";
@import "components/nominal";
@import "components/boardBodySvg";



.fon {
    height: 316px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D1A 26%, #1D1B2D73 50%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
}

.full-size {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 20;
}