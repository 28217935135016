.pc{
    .board-body{
        width: 956px;
        height: 170px;
        .smallBoard, .mediumBoard{
            display: none;
        }
        & svg{
            width: 100%;
            height: 100%;
            
        }
    }
}
    
.middlePc{
    .board-body{
        width: 708px;
        height: 141px;
        .smallBoard, .largeBoard{
            display: none;
        }
        // .mediumBoard{
        //     display: block;
        // }
        & svg{
            width: 100%;
            height: 100%;
           
        }
    }
}    

.smallPc{
    .board-body{
        width: 708px;
        height: 141px;
        .smallBoard, .largeBoard{
            display: none;
        }
        .mediumBoard{
            display: block;
        }
        & svg{
            width: 100%;
            height: 100%;
           
        }
    }
}  
.tab{
    .board-body{
        width: 708px;
        height: 141px;
        .smallBoard, .largeBoard{
            display: none;
        }
        .mediumBoard{
            display: block;
        }
        & svg{
            width: 100%;
            height: 100%;
            
        }
    }
}  

@media only screen and (max-width: 540px) {
    .board-body{
        width: 344px;
        height: 98px;
        .largeBoard, .mediumBoard{
            display: none;
        }
        .smallBoard{
            display: block;
        }
        & svg{
            width: 100%;
            height: 100%;
           
        }
    }
}