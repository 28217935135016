.hands-title {
    text-align: left;
    font: normal normal  17px/20px "SF Pro Text Medium";
    letter-spacing: 0.68px;
    color: #FFFFFF;
    text-shadow: 1px 1px 2px #000000;
    text-transform: uppercase;
}

.hands-body{
    padding-top: 4px;
}

.dealer-hand {
    width: fit-content;
    position: absolute;
    right: 839px;
    top: -62px;
    text-align: right;
    .cards-list {
        flex-direction: row-reverse;
    }
}

.player-hands{
    position: absolute;
    left: 840px;
    top: -62px;
    &-_2 {
        margin-top: 15px;
    }
}

.smallPc {
    .hands-title {
        font: normal normal 14px/16px "SF Pro Text Medium";
        letter-spacing: 0.56px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
    }

    .player-hands {
        left: 717px;
        top: -97px;
        &-_2 {
            margin-top: 10px;
        }
    }

    .dealer-hand {
        right: 721px;
        top: -97px;
    }
    .threeD {
        .dealer-hand {
            top: -92px;
        }
        .player-hands {
            top: -92px;
        }
    }
}

.tab {
    .hands-title {
        font: normal normal 14px/16px SF Pro Text Medium;
        letter-spacing: 0.56px;
    }

    .player-hands {
        left: 451px;
        top: 334px;
        &-_2 {
            margin-top: 10px;
        }
    }

    .dealer-hand {
        right: 452px;
        top: 334px;
    }

    .hands-body{
        padding-top: 3px;
    }

    .threeD {
        .dealer-hand {
            top: 328px;
            right: 447px;
        }
        .player-hands {
            top: 328px;
            left: 449px;
        }
    }
}

@media only screen and (max-width: 540px) {
    .hands-title {
        font: normal normal 12px/14px SF Pro Text Medium;
        letter-spacing: 0.48px;
    }

    .player-hands {
        left: 57.86vw;
        top: 24.88vh;
        &-_2 {
            margin-top: 4px;
        }
        .cardIcon {
            box-shadow: calc(-1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        }
    }

    .dealer-hand {
        right: 57.86vw;
        top: 24.88vh;
        .cardIcon {
            box-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
        }
    }

    .hands-body {
        padding-top: 3px;
        padding-right: 7px;
    }
}