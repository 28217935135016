.chips-list {
    position: relative;
    list-style: none;
    li {
        position: absolute;
        width: 71px;
    }
}
.threeD {
    .chips-list {
        li {
            width: 77px;
        }
    }
}

.smallPc {
    .chips-list {
        li {
            width: 60px;
        }
    }
    .threeD {
        .chips-list {
            li {
                width: 60px;
            }
        }
    }
}

.tab {
    .chips-list {
        li {
            width: 60px;
        }
    }
    .threeD {
        .chips-list {
            li {
                width: 60px;
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .chips-list {
        li {
            width: 10.6vw;
            max-width: 6.317vh;
        }
    }
    .threeD {
        .chips-list {
            li {
                width: 10.1vw;
                max-width: 6.317vh;
            }
        }
    }
}