.singleChip {
  width: calc(70px * var(--device-pixel-ratio));
  height: calc(70px * var(--device-pixel-ratio));
  border-radius: 50%;
  border: calc(1px * var(--device-pixel-ratio)) solid transparent;
  position: relative;
  transition: all 0.3s ease-in-out;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -47%);
    width: 100%;
  }

  &:hover {
    border-color: #EAEAEC;
  }

  &:active, &.active {
    background-color: rgba(#EAEAEC, 0.8);
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}