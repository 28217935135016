.user-actions-area {
  position: absolute;
  pointer-events: none;
  .board-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-other-box {
      width: 100%;
      height: calc(60px * var(--device-pixel-ratio));
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .other-left, & .other-right {
        width: calc(178px * var(--device-pixel-ratio));
        height: calc(60px * var(--device-pixel-ratio));
        border-radius: calc(13px * var(--device-pixel-ratio));
        position: relative;
        z-index: 0;
      }
      & .other-left {
        & .chips-list {
          position: absolute;
          right: calc(21px * var(--device-pixel-ratio));
          top: calc(-30px * var(--device-pixel-ratio));
        }
      }
      & .other-right {
        & .chips-list {
          position: absolute;
          top: calc(-30px * var(--device-pixel-ratio));
          left: calc(-52px * var(--device-pixel-ratio));
        }
      }
    }
    &-middle {
      width: 100%;
      height: calc(116px * var(--device-pixel-ratio));
      display: flex;
      justify-content: space-between;
      position: relative;
      & .middle-pair {
        width: calc(178px * var(--device-pixel-ratio));
        height: calc(106px * var(--device-pixel-ratio));
        border-radius: calc(13px * var(--device-pixel-ratio));
        position: absolute;
      }
      & .middle-player-banker {
         width: calc(224px * var(--device-pixel-ratio));
         height: calc(116px * var(--device-pixel-ratio));
         border-radius: calc(13px * var(--device-pixel-ratio));
        position: absolute;
       }
      & .middle-tie {
        width: calc(172px * var(--device-pixel-ratio));
        height: calc(100px * var(--device-pixel-ratio));
        border-radius: calc(13px * var(--device-pixel-ratio));
        position: absolute;
      }
      & div:nth-child(1) {
        z-index: 0;
        & .chips-list {
          position: absolute;
          left: calc(-50px * var(--device-pixel-ratio));
          bottom: calc(44px * var(--device-pixel-ratio));
        }
      }
      & div:nth-child(2){
        position: absolute;
        left: calc(102px * var(--device-pixel-ratio));
        top: calc(-15px * var(--device-pixel-ratio));
        z-index: 1;
        .chips-list {
          position: absolute;
          right: calc(117px * var(--device-pixel-ratio));
          bottom: calc(77px * var(--device-pixel-ratio));
        }
      }
      & div:nth-child(3) {
        left: calc(289px * var(--device-pixel-ratio));
        top: calc(-6px * var(--device-pixel-ratio));
        z-index: 2;
        & .chips-list {
          position: absolute;
          left: calc(-2px * var(--device-pixel-ratio));
          top: calc(-3px * var(--device-pixel-ratio));
        }
      }
      & div:nth-child(4) {
        top: calc(-15px * var(--device-pixel-ratio));
        right: calc(102px * var(--device-pixel-ratio));
        z-index: 1;
        & .chips-list {
          position: absolute;
          left: calc(45px * var(--device-pixel-ratio));
          bottom: calc(76px * var(--device-pixel-ratio));
        }
      }
      & div:nth-child(5) {
        right: calc(0px * var(--device-pixel-ratio));
        z-index: 0;
        & .chips-list {
          position: absolute;
          right: calc(24px * var(--device-pixel-ratio));
          bottom: calc(44px * var(--device-pixel-ratio));
        }
      }

    }
  }
  &.threeD {
    .board-body {
      &-other-box {
        & .other-left {
          & .chips-list {
            right: calc(13px * var(--device-pixel-ratio));
            top: calc(2px * var(--device-pixel-ratio));
          }
        }
        & .other-right {
          & .chips-list {
            top: calc(4px * var(--device-pixel-ratio));
            left: calc(-66px * var(--device-pixel-ratio));
          }
        }
      }
      &-middle {
        & div:nth-child(1) {
          & .chips-list {
            left: calc(-75px * var(--device-pixel-ratio));
            bottom: calc(54px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(2){
          .chips-list {
            right: calc(119px * var(--device-pixel-ratio));
            bottom: calc(66px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(3) {
          & .chips-list {
            left: calc(-4px * var(--device-pixel-ratio));
            top: calc(18px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(4) {
          & .chips-list {
            left: calc(41px * var(--device-pixel-ratio));
            bottom: calc(64px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(5) {
          & .chips-list {
            right: calc(3px * var(--device-pixel-ratio));
            bottom: calc(54px * var(--device-pixel-ratio));
          }
        }

      }
    }
  }
}

.smallPc {
  .user-actions-area {
    width: calc(672px* var(--device-pixel-ratio));
    .board-body {
      &-other-box {
        height: calc(56px * var(--device-pixel-ratio));
        & .other-left, & .other-right {
          width: calc(157px * var(--device-pixel-ratio));
          height: calc(56px * var(--device-pixel-ratio));
        }
        & .other-left {
          & .chips-list {
            right: calc(16px * var(--device-pixel-ratio));
            top: calc(-20px * var(--device-pixel-ratio));
          }
        }
        & .other-right {
          & .chips-list {
            top: calc(-21px * var(--device-pixel-ratio));
            left: calc(-44px * var(--device-pixel-ratio));
          }
        }
      }
      &-middle {
        height: calc(102px * var(--device-pixel-ratio));
        & .middle-pair {
          width: calc(162px * var(--device-pixel-ratio));
          height: calc(102px * var(--device-pixel-ratio));
        }
        & .middle-player-banker {
          width: calc(197px * var(--device-pixel-ratio));
          height: calc(116px * var(--device-pixel-ratio));
        }
        & .middle-tie {
          width: calc(156px * var(--device-pixel-ratio));
          height: calc(102px * var(--device-pixel-ratio));
        }
        & div:nth-child(1) {
          z-index: 0;
          & .chips-list {
            left: calc(40px * var(--device-pixel-ratio));
            bottom: calc(40px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(2){
          left: calc(97px * var(--device-pixel-ratio));
          top: calc(-22px * var(--device-pixel-ratio));
          .chips-list {
            right: calc(96px * var(--device-pixel-ratio));
            bottom: calc(61px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(3) {
          left: calc(256px * var(--device-pixel-ratio));
          top: calc(-16px * var(--device-pixel-ratio));
          & .chips-list {
            left: calc(-2px * var(--device-pixel-ratio));
            top: calc(1px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(4) {
          top: calc(-22px * var(--device-pixel-ratio));
          right: calc(98px * var(--device-pixel-ratio));
          & .chips-list {
            left: calc(38px * var(--device-pixel-ratio));
            bottom: calc(60px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(5) {
          & .chips-list {
            right: calc(100px * var(--device-pixel-ratio));
            bottom: calc(40px * var(--device-pixel-ratio));
          }
        }

      }
    }
    &.threeD {
      .board-body {
        &-other-box {
          & .other-left {
            & .chips-list {
              right: calc(8px * var(--device-pixel-ratio));
              top: calc(-2px * var(--device-pixel-ratio));
            }
          }
          & .other-right {
            & .chips-list {
              top: calc(-2px * var(--device-pixel-ratio));
              left: calc(-51px * var(--device-pixel-ratio));
            }
          }
        }
        &-middle {
          & div:nth-child(1) {
            & .chips-list {
              left: calc(31px * var(--device-pixel-ratio));
              bottom: calc(48px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(2){
            .chips-list {
              right: calc(104px * var(--device-pixel-ratio));
              bottom: calc(67px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(3) {
            & .chips-list {
              left: calc(2px * var(--device-pixel-ratio));
              top: calc(16px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(4) {
            & .chips-list {
              left: calc(45px * var(--device-pixel-ratio));
              bottom: calc(67px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(5) {
            & .chips-list {
              right: calc(90px * var(--device-pixel-ratio));
              bottom: calc(49px * var(--device-pixel-ratio));
            }
          }

        }
      }
    }
  }
}

.tab {
  .user-actions-area {
    width: calc(672px* var(--device-pixel-ratio));
    .board-body {
      &-other-box {
        height: calc(58px * var(--device-pixel-ratio));
        & .other-left, & .other-right {
          width: calc(158px * var(--device-pixel-ratio));
          height: calc(58px * var(--device-pixel-ratio));
        }
        & .other-left {
          & .chips-list {
            right: calc(18px * var(--device-pixel-ratio));
            top: calc(-20px * var(--device-pixel-ratio));
          }
        }
        & .other-right {
          & .chips-list {
            top: calc(-20px * var(--device-pixel-ratio));
            left: calc(-45px * var(--device-pixel-ratio));
          }
        }
      }
      &-middle {
        height: calc(114px * var(--device-pixel-ratio));
        & .middle-pair {
          width: calc(165px * var(--device-pixel-ratio));
          height: calc(104px * var(--device-pixel-ratio));
        }
        & .middle-player-banker {
          width: calc(194px * var(--device-pixel-ratio));
          height: calc(114px * var(--device-pixel-ratio));
        }
        & .middle-tie {
          width: calc(156px * var(--device-pixel-ratio));
          height: calc(101px * var(--device-pixel-ratio));
        }
        & div:nth-child(1) {
          z-index: 0;
          & .chips-list {
            left: calc(40px * var(--device-pixel-ratio));
            bottom: calc(40px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(2){
          left: calc(99px * var(--device-pixel-ratio));
          top: calc(-18px * var(--device-pixel-ratio));
          .chips-list {
            right: calc(97px * var(--device-pixel-ratio));
            bottom: calc(60px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(3) {
          left: calc(258px * var(--device-pixel-ratio));
          top: calc(-12px * var(--device-pixel-ratio));
          & .chips-list {
            left: calc(-2px * var(--device-pixel-ratio));
            top: calc(1px * var(--device-pixel-ratio));
          }
        }
        & div:nth-child(4) {
          top: calc(-17px * var(--device-pixel-ratio));
          right: calc(99px * var(--device-pixel-ratio));
          & .chips-list {
            left: calc(35px * var(--device-pixel-ratio));
            bottom: calc(61px * var(--device-pixel-ratio));;
          }
        }
        & div:nth-child(5) {
          & .chips-list {
            right: calc(104px * var(--device-pixel-ratio));
            bottom: calc(40px * var(--device-pixel-ratio));
          }
        }

      }
    }
    &.threeD {
      .board-body {
        &-other-box {
          & .other-left {
            & .chips-list {
              right: calc(11px * var(--device-pixel-ratio));
              top: calc(8px * var(--device-pixel-ratio));
            }
          }
          & .other-right {
            & .chips-list {
              top: calc(8px * var(--device-pixel-ratio));
              left: calc(-50px * var(--device-pixel-ratio));
            }
          }
        }
        &-middle {
          & div:nth-child(1) {
            & .chips-list {
              left: calc(28px * var(--device-pixel-ratio));
              bottom: calc(39px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(2){
            .chips-list {
              right: calc(103px * var(--device-pixel-ratio));
              bottom: calc(57px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(3) {
            & .chips-list {
              left: calc(0px * var(--device-pixel-ratio));
              top: calc(24px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(4) {
            & .chips-list {
              left: calc(43px * var(--device-pixel-ratio));
              bottom: calc(59px * var(--device-pixel-ratio));
            }
          }
          & div:nth-child(5) {
            & .chips-list {
              right: calc(88px * var(--device-pixel-ratio));
              bottom: calc(40px * var(--device-pixel-ratio));
            }
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .user-actions-area {
    width: 89.6vw;
    height: 100%;
    max-width: 50.37vh;
    .board-body {
      &-other-box {
        order: 1;
        height: 8vw;
        max-height: 4.4977vh;
        & .other-left, & .other-right {
          width: 44.266vw;
          height: 8vw;
          max-height: 4.4977vh;
        }
        & .other-left {
          & .chips-list {
            right: 40%;
            top: -100%;
          }
        }
        & .other-right {
          & .chips-list {
            top: -100%;
            left: 16%;
          }
        }
      }
      &-middle {
        order: 0;
        height: 35.2vw;
        max-height: 18.59vh;
        & .middle-player-banker {
          width: 40vw;
          height: 20.266vw;
          max-height: 11.394vh;
          max-width: 22.488vh;
        }
        & .middle-pair {
          width: 26.66vw;
          height: 12.8vw;
          max-height: 7.196vh;
          max-width: 14.99vh;
        }
        & .middle-tie {
          width: 29.33vw;
          height: 17.066vw;
          max-height: 9.595vh;
          max-width: 16.49vh;
        }
        & div:nth-child(1){
          left: 0;
          top: 61.5%;
          .chips-list {
            left: -15%;
            bottom: 55%;
          }
        }
        & div:nth-child(2) {
          z-index: 0;
          top: 0;
          left: 0;
          & .chips-list {
            left: 43%;
            bottom: 53%;
          }
        }
        & div:nth-child(3) {
          left: 33.2%;
          top: 3%;
          & .chips-list {
            left: 4.4%;
            top: 12%;
          }
        }
        & div:nth-child(5) {
          top: 61.5%;
          right: 0;
          & .chips-list {
            right: 25%;
            bottom: 60%;
          }
        }
        & div:nth-child(4) {
          top: 0;
          right: 0;
          & .chips-list {
            left: 30%;
            bottom: 55%;
          }
        }

      }
    }
  }
}