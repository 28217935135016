.middle-area {
    width: 752px;
    height: 170px;
}

.tab {
    .middle-area {
        width: 672px;
        height: 162px;
    }
}

.smallPc {
    .middle-area {
        width: 672px;
        height: 162px;
    }
}

@media only screen and (max-width: 540px) {
    .middle-area {
        width:89.6vw;
        height: 43.2vw;
        max-width: 50.37vh;
        & .board-middle-area-mobile {
            width: 89.6vw;
            max-height: 24.287vh;
        }
    }
}